import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration.js';

import { Renderizable } from './renderizable.ts';

export class HudaInterval extends Renderizable {
  private tickerIntervalId?: number;
  public startTime: Dayjs | null = null;
  public stopTime: Dayjs | null = null;

  constructor() {
    super();
  }

  get diff() {
    if (!this.startTime) {
      throw new Error('HudaInterval: has not started yet');
    }
    return (this.stopTime ?? dayjs()).diff(this.startTime);
  }

  get duration() {
    return dayjs.duration(this.diff);
  }

  private startTicker() {
    this.tickerIntervalId = window.setInterval(this.onTick.bind(this), 10);
  }

  private onTick() {
    this.renderUpdate();

    if (!this.startTime) {
      window.clearInterval(this.tickerIntervalId);
    }

    if (this.stopTime) {
      window.clearInterval(this.tickerIntervalId);
    }
  }

  start() {
    if (this.startTime) {
      throw new Error('HudaInterval: already started');
    }
    this.startTime = dayjs();
    this.startTicker();
  }

  stop() {
    if (!this.startTime) {
      throw new Error('HudaInterval: not started yet');
    }

    if (this.stopTime) {
      throw new Error('HudaInterval: already stopped');
    }
    this.stopTime = dayjs();
  }

  getClockTime() {
    if (!this.startTime) {
      return '00:00:00.000';
    }
    return HudaInterval.getClockTime(this.duration);
  }

  static getClockTime(duration: Duration) {
    return duration.format('HH:mm:ss.SSS');
  }
}
