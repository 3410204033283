import { Duration } from 'dayjs/plugin/duration.js';

import { HudaRound } from './round.ts';
import { HudaInterval } from './interval.ts';
import { Renderizable } from './renderizable.ts';

export class HudaSession extends Renderizable {
  public mainInterval = new HudaInterval();
  public rounds: HudaRound[] = [];
  public currentRound: HudaRound | null = null;

  constructor(
    public roundDelayTime: Duration,
    public minRoundTime: Duration,
    public maxRoundTime: Duration
  ) {
    super();
    this.mainInterval.pipeUpdates(this);
  }

  get numeratedRounds() {
    let roundNumber = 0;
    const rounds: { number: number; round: HudaRound }[] = [];

    for (const round of this.rounds) {
      rounds.push({ number: roundNumber, round });
      if (round.effective) {
        roundNumber++;
      }
    }

    return rounds;
  }
}
