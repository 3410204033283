export type IRenderizableListener = {
  (): void;
};

export abstract class Renderizable {
  private listeners = new Set<IRenderizableListener>();
  private chainedRenderizables = new Map<Renderizable, IRenderizableListener>();

  protected renderUpdate() {
    for (const listener of this.listeners) {
      listener();
    }
  }

  onRenderUpdate(listener: IRenderizableListener) {
    this.listeners.add(listener);
  }

  offRenderUpdate(listener: IRenderizableListener) {
    this.listeners.delete(listener);
  }

  pipeUpdates(renderizable: Renderizable) {
    let listener = this.chainedRenderizables.get(renderizable);
    if (!listener) {
      listener = () => renderizable.renderUpdate();
    }

    this.chainedRenderizables.set(renderizable, listener);
    this.onRenderUpdate(listener);
  }

  unpipeUpdates(renderizable: Renderizable) {
    const listener = this.chainedRenderizables.get(renderizable);
    if (!listener) {
      return;
    }

    this.chainedRenderizables.delete(renderizable);
    this.offRenderUpdate(listener);
  }
}
