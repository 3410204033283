import {
  Button,
  Center,
  Flex,
  NumberInput,
  Paper,
  Progress,
  Text,
  Title
} from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useReducer, useState } from 'react';

import { HudaSession } from './utils/session.ts';
import { HudaRound } from './utils/round.ts';

const ExecProgramView = () => {
  const ticker = useReducer((s) => s + 1, 0)[1];

  const [roundDelayTime, setRoundDelayTime] = useState(3);
  const [minRoundTime, setMinRoundTime] = useState(30);
  const [maxRoundTime, setMaxRoundTime] = useState(45);

  const [currentSession, setCurrentSession] = useState<HudaSession | null>(
    null
  );
  const [currentRound, setCurrentRound] = useState<HudaRound | null>(null);

  const doStartSession = () => {
    const session = new HudaSession(
      dayjs.duration(roundDelayTime, 'seconds'),
      dayjs.duration(minRoundTime, 'seconds'),
      dayjs.duration(maxRoundTime, 'seconds')
    );

    session.mainInterval.start();

    setCurrentSession(session);
  };

  const doStopSession = () => {
    if (!currentSession) {
      return;
    }

    if (currentSession.mainInterval.stopTime) {
      return;
    }

    currentSession?.mainInterval.stop();
  };

  const doStartRound = () => {
    if (!currentSession) {
      return;
    }

    doStopRound();

    const round = new HudaRound(currentSession);
    round.start();

    setCurrentRound(round);
  };

  const doPauseUnpauseRound = () => {
    if (!currentRound) {
      return;
    }

    if (!currentRound.currentInterval) {
      currentRound.start();
      return;
    }

    currentRound.pause();
  };

  const doStopRound = () => {
    if (!currentRound) {
      return;
    }

    if (!currentRound.currentInterval) {
      currentRound.start();
    }

    currentRound.stop();
    setCurrentRound(null);
  };

  useEffect(() => {
    if (currentSession) {
      currentSession.onRenderUpdate(ticker);
    }

    if (currentRound) {
      currentRound.onRenderUpdate(ticker);
    }

    return () => {
      if (currentSession) {
        currentSession.offRenderUpdate(ticker);
      }

      if (currentRound) {
        currentRound.offRenderUpdate(ticker);
      }
    };
  }, [currentSession, currentRound]);

  return (
    <Flex direction="column" gap="md">
      <Paper shadow="xs" p="md" w={'100%'} bg={'blueish.0'}>
        <Center w="100%">
          Time: {currentSession?.mainInterval.getClockTime() ?? '00:00:00.000'}
        </Center>
      </Paper>

      {!currentSession && (
        <Flex direction="column" gap="md">
          <NumberInput
            label="Round Delayed Start (s)"
            suffix="s"
            value={roundDelayTime}
            onChange={(value) => {
              const parsed = parseInt(String(value ?? ''));
              !Number.isNaN(parsed) && setRoundDelayTime(parsed);
            }}
          />
          <NumberInput
            label="Round Min Time (s)"
            suffix="s"
            value={minRoundTime}
            onChange={(value) => {
              const parsed = parseInt(String(value ?? ''));
              !Number.isNaN(parsed) && setMinRoundTime(parsed);
            }}
          />
          <NumberInput
            label="Round Max Time (s)"
            suffix="s"
            value={maxRoundTime}
            onChange={(value) => {
              const parsed = parseInt(String(value ?? ''));
              !Number.isNaN(parsed) && setMaxRoundTime(parsed);
            }}
          />
          <Button onClick={doStartSession}>Start Session</Button>
        </Flex>
      )}

      {currentSession && (
        <Flex direction="column" gap="md">
          <Button onClick={doStopSession}>Stop Session</Button>

          <Paper shadow="xs" p="md" w={'100%'} bg={'blueish.0'}>
            <Flex direction="column" gap="md">
              {currentRound && (
                <>
                  <Title order={3}>Current Round</Title>
                  <Text>Time: {currentRound.getClockTime()}</Text>
                  <Text>
                    MinProgression:{' '}
                    {currentRound.getRatioPercentageFormatted('min')}
                  </Text>
                  <Progress value={currentRound.getRatioPercentage('min')} />
                  <Text>
                    MaxProgression:{' '}
                    {currentRound.getRatioPercentageFormatted('max')}
                  </Text>
                  <Progress value={currentRound.getRatioPercentage('max')} />
                </>
              )}
              <Button onClick={doStartRound}>Start Round</Button>
              {currentRound && (
                <>
                  <Button onClick={doPauseUnpauseRound}>
                    {currentRound.currentInterval ? 'Pause' : 'Unpause'} Round
                  </Button>
                  <Button onClick={doStopRound}>Stop Round</Button>
                </>
              )}
            </Flex>
          </Paper>

          {currentSession.numeratedRounds
            .toReversed()
            .filter((round) => round.round.currentInterval?.stopTime)
            .map(({ number, round }) => (
              <Paper
                shadow="xs"
                p="md"
                w={'100%'}
                bg={'blueish.0'}
                key={round.id}
              >
                <Flex direction="row" gap="md">
                  <Text
                    style={{
                      textDecoration: !round.effective ? 'line-through' : ''
                    }}
                  >
                    #{number + 1} :: {round.getClockTime()} :: [
                    {round.getRatioPercentageFormatted('min')}
                    ][
                    {round.getRatioPercentageFormatted('max')}]
                  </Text>
                </Flex>
              </Paper>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export { ExecProgramView };
