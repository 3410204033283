import { Center, Image, AppShell, Text } from '@mantine/core';

import AppLogoSvg from '@/assets/logos/logo.svg';

const Header = () => {
  return (
    <AppShell.Header>
      <Center px="sm" h="100%" w="100%" pos="relative">
        <Image src={AppLogoSvg} h="40px" w="40px" pos="absolute" left="10px" />
        <Text c="blueish.4">Huda</Text>
      </Center>
    </AppShell.Header>
  );
};

export { Header };
