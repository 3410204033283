import {
  AppShell,
  DefaultMantineColor,
  MantineColorsTuple,
  MantineProvider,
  colorsTuple,
  createTheme
} from '@mantine/core';
import { Outlet } from 'react-router-dom';

import { Header } from './common/components/index.ts';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';

type AppColorNames = 'blueish' | 'white' | 'black' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<AppColorNames, MantineColorsTuple>;
  }
}

const AppTheme = createTheme({
  colors: {
    white: colorsTuple('#ffffff'),
    black: colorsTuple('#000000'),
    blueish: [
      '#e2ecff',
      '#b2c5ff',
      '#809fff',
      '#4f78fc',
      '#1e52fa',
      '#0538e1',
      '#002cb0',
      '#001f7f',
      '#00134f',
      '#000620'
    ]
  },
  primaryColor: 'blueish',
  fontFamily: '"Varela Round", sans-serif'
});

const AppLayout = () => {
  return (
    <MantineProvider theme={AppTheme}>
      <AppShell padding="md" header={{ height: '50px' }}>
        <Header />
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
};

export { AppLayout };
