import dayjs from 'dayjs';

import { HudaInterval } from './interval.ts';
import { HudaSession } from './session.ts';
import { Renderizable } from './renderizable.ts';

export class HudaRound extends Renderizable {
  public readonly id = Math.floor(Math.random() * 1000000);
  public delayInterval = new HudaInterval();
  public intervals: HudaInterval[] = [];
  public currentInterval: HudaInterval | null = null;

  constructor(public session: HudaSession) {
    super();
    session.rounds.push(this);
  }

  get accumulativeDiff() {
    return this.intervals.reduce((pv, cv) => {
      return pv + cv.diff;
    }, 0);
  }

  get accumulativeDuration() {
    return dayjs.duration(this.accumulativeDiff);
  }

  get minCompletionRatio() {
    return this.accumulativeDiff / this.session.minRoundTime.as('milliseconds');
  }

  get maxCompletionRatio() {
    return this.accumulativeDiff / this.session.maxRoundTime.as('milliseconds');
  }

  get effective() {
    return this.minCompletionRatio > 1;
  }

  start() {
    if (this.currentInterval) {
      throw new Error('HudaRound: already started');
    }

    this.currentInterval = new HudaInterval();
    this.currentInterval.start();
    this.currentInterval.pipeUpdates(this);
    this.intervals.push(this.currentInterval);
  }

  pause() {
    if (!this.currentInterval) {
      throw new Error('HudaRound: not started yet');
    }

    this.currentInterval.stop();
    this.currentInterval = null;
  }

  stop() {
    if (!this.currentInterval) {
      throw new Error('HudaRound: not started yet');
    }

    if (this.currentInterval.stopTime) {
      throw new Error('HudaRound: already stopped');
    }

    this.currentInterval.stop();
  }

  getClockTime() {
    return HudaInterval.getClockTime(this.accumulativeDuration);
  }

  getDelayClockTime() {}

  getRatioPercentage(type: 'min' | 'max') {
    const ratio =
      type === 'min' ? this.minCompletionRatio : this.maxCompletionRatio;
    return HudaRound.getRatioPercentage(ratio);
  }

  getRatioPercentageFormatted(type: 'min' | 'max') {
    const ratio =
      type === 'min' ? this.minCompletionRatio : this.maxCompletionRatio;
    return HudaRound.getRatioPercentageFormatted(ratio);
  }

  static getRatioPercentage(ratio: number) {
    return ratio * 100;
  }

  static getRatioPercentageFormatted(ratio: number) {
    return `${Math.floor(this.getRatioPercentage(ratio))}%`;
  }
}
